<script>
import appConfig from "@/app.config";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { required, url } from "vuelidate/lib/validators";

import {
  userMethods,
  commonMethods
} from "@/state/helpers";

export default {
  page: {
    title: "Company",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      items:[],
      company:{},
      submitted : false,
      companyPicture : null,
      user : null,
    }
  },
  computed: {
   
  },
  methods: {
    ...userMethods,
    ...commonMethods,

    updateCompanyClicked(){

      this.submitted = true;
      this.$v.$touch();
      
      if (this.$v.$invalid) {
        return;
      }

      let loader = this.$loading.show();

      this.updateCompany(this.company).then((res)=>{
				if(res&& res.data){
					this.submitted= false;
          this.$notify({ type: 'success', text: this.$t("company.update_success"), title: this.$t("company.title") });
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("company.update_error"), title: this.$t("company.title") });
			}).finally(()=>{
				loader.hide();
			});
    },

    loadCompany() {
      let loader = this.$loading.show();
			this.getCompany(this.user.company._id).then((res)=>{
				if(res&& res.data){
					this.submitted= false;
					this.company= res.data;
          this.companyPicture = this.company.logo;
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("company.get_company_error"),title: this.$t("company.title") });
			}).finally(()=>{
				loader.hide();
			});
    },

    updateCompanyPicture(){
      var file = this.$refs.companyPicture.files[0];
      this.$refs.companyPicture.value = null;

      if(file){
        this.uploadMedia(file)
				.then((res) => {
            this.companyPicture = res.data.url;
            this.company.logo = res.data.url;
            return true;                  
					})
					.catch(() => {
            this.$notify({ type: 'error', text: this.$t("company.upload_image_error"),title:  this.$t("company.title") });
					})
			}
    },
    removeCompanyLogo(){
      this.company.logo=null;
      this.companyPicture = null;
    },

  },
  validations: {
    company: {
      name: {
        required,
      },
      custom_domain: {
        url
      }
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("auth.currentUser")).user;

    if(this.user && this.user.company){
      this.loadCompany();
    }
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('company.title')" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
                <div class="col-sm-6 flex">
                  <div class="mb-3">
                    <label for="companyName">{{this.$t("company.name")}}*</label>
                    <input
                      id="companyName"
                      v-model="company.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{'is-invalid': submitted && $v.company.name.$error}"
                    />
                    <div
                      v-if="submitted && !$v.company.name.required"
                      class="invalid-feedback">
                        {{this.$t("company.name_required")}}
                    </div>
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 flex">
                  <div class="mb-3">
                    <label for="companyPicture">{{this.$t("company.logo")}}</label>
                    <input
                          ref="companyPicture"
                          @change="updateCompanyPicture"
                          accept="image/*"
                          class="form-control "
                          id="companyPicture"
                          type="file"/>
                  </div>
                </div>
                <div class="col-sm-6 flex" v-if="companyPicture">
                    <div class="avatar-xs me-3 edit-picture-button" v-on:click="removeCompanyLogo()">
                      <span class="avatar-title bg-light rounded-circle font-size-15 icon-color-grey">
                        <i class="bx bxs-trash"></i>
                      </span>
                    </div>
                    <img
                      class="rounded me-2"
                      height="120"
                      width="400"
                      :src="companyPicture"
                    />
                </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="role">Dominio</label>
                  <b-form-input
                    v-model="company.custom_domain"
                    type="text"
                    :class="{'is-invalid': submitted && $v.company.custom_domain.$error}"
                    disabled="true">
                  </b-form-input>
                </div>
              </div>
             
              </div>
            <!--<div class="row">
              <div class="col-sm-6 flex">
                  <div class="mb-3">
                    <label for="companyAvailability">{{this.$t('company.availability')}}</label>
                    <Availability></Availability>
                  </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      
    </div>
    <div class="row mt-4">
          <div class="col-sm-6">
          </div>
          <!-- end col -->
          <div class="col-sm-6">
            <div class="text-end">
              <b-button variant="primary" class="w-md ms-3 mb-3" @click="updateCompanyClicked()">{{$t('common.confirm')}}</b-button>
            </div>
          </div>
          <!-- end col -->
        </div>
  </Layout>
</template>

<style scoped>

.edit-picture-button {
    height: 1.5rem !important;
    width: 1.5rem !important;
    top: -5px;
    position: absolute;
    color:#adb5bd
}

.icon-color-grey {
  color:#adb5bd
}
</style>

  
